<template>
  <div>
    <div class="d-flex justify-center flex-wrap">
      <div v-for="tag in tagList" :key="tag.token">
        <v-btn-toggle group text color="red" v-model="currTag">
          <v-btn class="mx-2" :value="tag.token" @click="removeItem(tag.token)">{{ tag.nameRu }}</v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div>
      <v-row dense align="stretch">
        <v-col v-for="cafe in cafes" :key="cafe.id" class="px-4" sm="6" md="4" lg="4" xl="4" cols="12">
          <v-card class="my-4 mx-2" width="350">
            <v-img height="150" :src="url() + cafe.image.path"></v-img>
            <v-card-title class="py-2">{{ cafe.nameRu }}</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="pb-0 pt-1">Открыто с {{ cafe.operTimeStart.slice(0, -3) }} дo {{ cafe.operTimeEnd.slice(0, -3) }}</v-card-text>
            <v-card-actions class="pt-1">
              <v-btn color="red" text @click="getCafeMenu(cafe)"> Подробнее </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import config from '@/config/config';

export default {
  data() {
    return {
      currTags: [],
      currTag: ''
    };
  },
  props: {
    cafeList: {
      type: Array,
      required: true
    },
    tagList: {
      type: Array,
      required: true
    }
  },

  computed: {
    cafes() {
      let cafesArray = [];
      if (this.cafeList !== undefined && this.cafeList !== null && this.cafeList.length > 0) {
        this.cafeList.forEach((cafe) => {
          if (cafe.onlyCorpo === false) cafesArray.push(cafe);
        });
      }
      if (this.currTag !== undefined && this.currTag !== null && this.currTag.length > 0) {
        return cafesArray.filter((cafe) => {
          return cafe.tags.some((tagsObj) => tagsObj.token === this.currTag);
        });
      }
      return cafesArray;
    }
  },
  methods: {
    removeItem(token) {
      if (token === this.currTag) this.currTag = '';
    },
    getCafeMenu(cafe) {
      if (localStorage.getItem('cafeToken') === cafe.token || !localStorage.getItem('cafeToken')) {
        localStorage.setItem('takeoutsArr', JSON.stringify(cafe.takeouts));
      }
      this.$router.push({
        name: 'cafeMenu',
        params: { token: cafe.token, cafeInfo: cafe }
      });
    },
    chooseTag(tag) {
      this.currTags.push(tag);
    },
    url() {
      return window.location.origin + '/';
    }
  }
};
</script>
<style scoped>
::v-deep .v-toolbar__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
