<template>
  <div class="my-3">
    <v-form ref="form" @submit.prevent="chooseStation">
      <v-autocomplete label="Выберите вокзал" outlined v-model="stationToken" v-on:change="chooseStation" :items="getStation()"></v-autocomplete>
      <!-- <v-btn type="submit">Подтвердить</v-btn> -->
    </v-form>
    <div v-if="this.isCafeListLoading === true && this.isCafeListError === false">Пожалуйста, подождите...</div>
    <div v-else-if="this.cafeListResult === false && this.isCafeListError === true">
      {{ this.cafeListErrorMessage }}
    </div>
    <div v-else-if="this.showCafes === true && this.isCafeListError === false && this.isCafeListLoading === false">
      <!-- <ClientTagList :tagList="this.tags" :choosenTag="choosenTag" /> -->
      <v-autocomplete label="Точка выдачи" outlined v-model="takeoutToken" v-on:change="chooseTakeout" :items="getTakeouts()"></v-autocomplete>

      <ClientCafeList :cafeList="this.cafeList" :tagList="this.tags" />
    </div>
    <div>
      <ClientCart />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ClientCafeList from '@/components/food/ClientCafeList';
import ClientCart from '@/components/food/ClientCart';

export default {
  data() {
    return {
      search: '',
      stationToken: localStorage.getItem('stationToken'),
      takeoutToken: localStorage.getItem('takeout'),
      showCafes: false,
      choosenTag: [],
      takeouts: [],
      filtredCafeListByTakeouts: []
    };
  },
  components: {
    ClientCafeList,
    ClientCart
  },
  mounted() {
    this.updateStationsList();
    this.updateTagList();
    this.updateRestaurants();
  },
  computed: {
    stations() {
      let stations = [];
      if (this.stationsList !== undefined && this.stationsList !== null) {
        this.stationsList.forEach((station) => {
          stations.push(station);
        });
      }
      return stations;
    },
    tags() {
      let tagsArray = [];
      if (this.tagsList !== undefined && this.tagsList !== null && this.tagsList.length > 0) {
        this.tagsList.forEach((tag) => {
          tagsArray.push(tag);
        });
      }
      return tagsArray;
    },

    ...mapGetters('station', ['stationsList']),
    ...mapGetters('station', ['isStationsLoading']),
    ...mapGetters('station', ['isStationsError']),
    ...mapGetters('station', ['stationsErrorMessage']),
    ...mapGetters('cafe', ['cafeList']),
    ...mapGetters('cafe', ['isCafeListLoading']),
    ...mapGetters('cafe', ['cafeListResult']),
    ...mapGetters('cafe', ['isCafeListError']),
    ...mapGetters('cafe', ['cafeListErrorMessage']),
    ...mapGetters('tag', ['tagsList']),
    ...mapGetters('tag', ['isTagsListLoading']),
    ...mapGetters('tag', ['isTagsListError']),
    ...mapGetters('tag', ['tagsListResult']),
    ...mapGetters('tag', ['tagsListErrorMessage'])
  },
  methods: {
    getStation() {
      let list = [];
      if (this.stations !== undefined && this.stations !== null && this.stations.length >= 0) {
        this.stations.forEach((station) => {
          if (station.onlyCorpo === false) list.push({ text: station.nameExpFullRu, value: station.token });
        });
      }
      return list;
    },
    getTakeouts() {
      let list = [];
      if (this.takeouts.length > 0) {
        this.takeouts.forEach((takeout) => list.push({ text: takeout.nameFullRu, value: takeout.token }));
      } else if (JSON.parse(localStorage.getItem('takeoutsArr')))
        JSON.parse(localStorage.getItem('takeoutsArr')).forEach((takeout) => list.push({ text: takeout.nameFullRu, value: takeout.token }));
      return list;
    },
    chooseStation() {
      if (this.$refs.form.validate()) {
        this.takeouts = this.stations.find((station) => station.token === this.stationToken).takeouts;
        this.fetchCafeList(this.stationToken).then(() => {
          if (this.cafeListResult === true) {
            this.showCafes = true;
            if (!localStorage.getItem('cafeToken')) localStorage.setItem('takeoutsArr', JSON.stringify(this.takeouts));
            localStorage.setItem('stationToken', this.stationToken);
          }
        });
      }
    },
    chooseTakeout() {
      this.filtredCafeListByTakeouts = [];

      localStorage.setItem('takeout', this.takeoutToken);
      // this.cafeList.forEach((cafe) => cafe.takeouts.filter((takeout) => takeout.token === this.takeoutToken));
      let smth = this.cafeList.forEach((cafe) => {
        return cafe.takeouts.filter((takeout) => takeout.token === this.takeoutToken);
      });
    },
    updateStationsList() {
      this.fetchStationsList();
    },
    updateTakeoutsList() {
      this.fetchTakeoutsList();
    },
    updateTagList() {
      this.fetchTagList();
    },
    updateRestaurants() {
      if (this.stationToken !== null) {
        this.fetchCafeList(localStorage.getItem('stationToken')).then(() => {
          this.showCafes = true;
          localStorage.setItem('stationToken', this.stationToken);
        });
      }
    },
    ...mapActions('station', ['fetchStationsList']),
    ...mapActions('station', ['fetchStationsList']),
    ...mapActions('takeouts', ['fetchTakeoutsList']),
    ...mapActions('cafe', ['fetchCafeList']),
    ...mapActions('tag', ['fetchTagList'])
  }
};
</script>
